function loadImage (el) {
  // const imageElement = Array.from(el.children).find(
  //   el => el.nodeName === 'IMG'
  // )
  const imageElement = el
  if (imageElement) {
    imageElement.addEventListener('load', () => {
      setTimeout(() => el.classList.add('loaded'), 100)
    })
    imageElement.addEventListener('error', () => console.error('error'))
    imageElement.src = imageElement.dataset.url
  }
}

function handleIntersect (entries, observer, el) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      loadImage(el)
      observer.unobserve(el)
    }
  })
}

function load (el) {
  if (window.IntersectionObserver) {
    createObserver(el)
  } else {
    loadImage(el)
  }
}

function createObserver (el) {
  const options = {
    root: null,
    threshold: '0'
  }
  const observer = new IntersectionObserver((entries, observer) => handleIntersect(entries, observer, el), options)
  observer.observe(el)
}

export default {
  // twoWay: true,

  update: function (value, obj, newEL, oldEl) {
    if (value.getAttribute('src') && value.getAttribute('src') !== value.getAttribute('data-url')) {
      loadImage(value)
    }
  },

  inserted: el => {
    load(el)
  }
}
