import axios from 'axios'

const Loader = {
  items: {
    buildingStandards: null,
    buildyearTypes: null,
    lagebewertung: null,
    matchingTable: null,
    objektToMDC: null,
    objektTypeMap: null,
    standardLevels: null,
    waegungsanteile: null,
    zustandData: null
  },

  mdcItems: {
    modernizationCostsAll: null,
    modernizationCostsAprox: null,
    modernizationCostsDetailed: null
  },

  mdcCalcItems: {
    regionalFactors: null,
    // mdc_efh_zfh_bef1945: null,
    // mdc_efh_zfh_aft1945: null,
    mdc_efh_zfh_bef1945_stlb: null,
    mdc_efh_zfh_aft1945_stlb: null,
    georef_germany_post_codes: null
  },

  async loadAll (keys = 'items') {
    return new Promise((resolve) => {
      axios.all(Object.keys(this[keys]).map(o => Loader.load(o, keys))).then(resolve)
    })
  },

  async load (name, keys = 'items') {
    if (Loader[keys][name]) {
      return Loader[keys][name]
    }

    return new Promise((resolve) => {
      axios.get(`/project-datas/${name}.json`).then((response) => {
        Loader[keys][name] = response.data
        resolve(Loader[keys][name])
      })
    })
  }
}

export const all = () => {
  return Loader.items
}

function toFixedNumber (num, val = 1e2) {
  const res = Math.round(num * val) / val
  return (res === Infinity || !res) ? 0 : res
}

export const loadMdc = async (mdc_index) => {
  await Loader.loadAll('mdcItems')

  const tmp = JSON.clone(Loader.mdcItems)

  let masterKey = 'old'
  if (mdc_index) {
    masterKey = '2020'
    const oldIndex = 117.7

    Object.keys(tmp.modernizationCostsDetailed[masterKey]).forEach(key => {
      const data = tmp.modernizationCostsDetailed[masterKey][key]
      if (data.Mittelpreis) { data.Mittelpreis = toFixedNumber(data.Mittelpreis * (mdc_index / oldIndex)) }
      if (data.minPreis) { data.minPreis = toFixedNumber(data.minPreis * (mdc_index / oldIndex)) }
      if (data.maxPreis) { data.maxPreis = toFixedNumber(data.maxPreis * (mdc_index / oldIndex)) }
    })
  }
  return {
    modernizationCostsAll: tmp.modernizationCostsAll[masterKey],
    modernizationCostsAprox: tmp.modernizationCostsAprox[masterKey],
    modernizationCostsDetailed: tmp.modernizationCostsDetailed[masterKey]
  }
}

export const loadMdcCalcItems = async () => {
  await Loader.loadAll('mdcCalcItems')
  return Loader.mdcCalcItems
}

export const globalDataProject = (name) => {
  return Loader.items[name]
}

export default Loader
