import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

Vue.use(Zendesk, {
  key: 'ca4a3c75-b539-48bb-aa1e-f35e3ff05029',
  // disabled: true,
  // we might hide it for bo and just load for experts in future
  hideOnLoad: true,
  settings: {
    /*
      see: https://developer.zendesk.com/embeddables/docs/widget/settings#menuoptions for options
    */
    webWidget: {
      launcher: {
        label: {
          '*': '­'
        },
        mobile: {
          labelVisible: false
        }
      }
    }
  }
})
