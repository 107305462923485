import { v4 as uuid } from 'uuid'
import auth from '@/core/auth'

const socket = {
  callbacks: [],
  connection: null,
  isAuthenticated: false,
  screenSubscriptions: {},
  events: {},

  init () {
    if (!auth || !auth.user || !auth.user.socket_address) {
      this.tryToConnect()
      return
    }

    if (this.events === undefined) {
      this.events = {}
    }

    this.waitingAttack = null
    this.isAuthenticated = false
    this.connection = new WebSocket(auth.user.socket_address)
    this.connection.onerror = this.tryToConnect
    this.connection.onopen = this.onOpen
    this.connection.onclose = this.onClose
    this.connection.onmessage = this.onMessage
    this.listen('authenticated', this.onAuthenticated)
  },

  tryToConnect () {
    socket.connection = null
    socket.isAuthenticated = false
    socket.callbacks = []
    if (socket.waitingAttack === null) {
      socket.waitingAttack = setTimeout(() => {
        socket.waitingAttack = null
        socket.init()
      }, 3000)
    }
  },

  send (type, data) {
    if (type === 'screen@unsubscribe') {
      socket.removeScreenSubscription(data)
    }

    if (this.isAuthenticated === false || socket.connection.readyState !== 1) {
      socket.whenReady(() => {
        socket.send(type, data)
      })
      return
    }

    socket.connection.send(JSON.stringify({
      type,
      data
    }))
  },

  whenReady (callback) {
    if (socket.isAuthenticated === true && socket.connection.readyState === 1) {
      return callback()
    }
    socket.callbacks.push(callback)
  },

  onOpen () {
    // When we open a page which we already login before, but our token is expired,
    // for a moment we can connect for websocket but then JS side clears login
    // information and we can't send token because we don't have any connection now.
    // This causes an error. So we should check still we have a valid connection.
    if (!socket.connection) {
      return
    }

    // Maybe it is still connecting... Yeah, it is weird but it could be.
    if (socket.connection.readyState !== WebSocket.OPEN) {
      return
    }

    socket.connection.send(JSON.stringify({
      parent: 'Internal',
      type: 'ClientAuthentication',
      payload: {
        token: auth.token
      }
    }))
  },

  onClose () {
    socket.isAuthenticated = false
    socket.tryToConnect()
  },

  onMessage (message) {
    let content = null
    try {
      if (typeof message.data === 'string') {
        content = JSON.parse(message.data)
      } else {
        content = message.data
      }
    } catch (error) {
      if (window.Sentry) {
        window.Sentry.captureException(error, { message: message.data })
      }
      return
    }

    // If this is a screen subscription pattern, we should do something special.
    // For exampe if we want to shows active subscripted users to the logged user,
    // we should have that kind of data first. There is a time latency in the
    // scenario. That's why we keep this data all the time.
    if (content && content.type && content.type.indexOf('screen@') === 0) {
      socket.onScreenHandler(content)
    }

    if (content.type) {
      socket.fire(content.type, content.data)
    }
  },

  onAuthenticated () {
    socket.isAuthenticated = true
    socket.callbacks.forEach(callback => callback())
    socket.callbacks = []
  },

  removeScreenSubscription (key) {
    if (socket.screenSubscriptions[key]) {
      delete socket.screenSubscriptions[key]
    }
  },

  onScreenHandler (item) {
    if (item && item.data) {
      socket.screenSubscriptions[item.data.data] = item.data.users
    }
  },

  listen (name, callback) {
    if (!this.events[name]) {
      this.events[name] = []
    }

    const id = uuid()
    this.events[name].push({
      uuid: id,
      callback: callback
    })
    return id
  },

  fire (name, data) {
    if (!this.events[name]) {
      return null
    }

    this.events[name].forEach(item => {
      if (typeof item.callback === 'function') {
        item.callback(data)
      }
    })
  },

  clear (name, uuid) {
    if (this.events[name] === undefined) {
      return
    }
    this.events[name] = this.events[name].filter(item => item.uuid !== uuid)
  },

  close () {
    // We should close connection if there is any. Otherwise if the user logged
    // out, the websocket connection still remains. We don't want it.
    if (this.connection) {
      this.connection.onclose = () => {}
      this.connection.close()
    }
    this.connection = null
    this.isAuthenticated = false
    this.callbacks = []
  }
}

export default socket
