import auth from '@/core/auth'

export default {

  // bind: (element, binding, vnode) => {
  //   const permissionValue = binding.value.permission
  //   auth.whenReady(() => {
  //     const isPermitted = auth.user.permissions.some((permission) => permission.value === permissionValue)
  //     if (isPermitted === false) {
  //       commentNode(element, vnode)
  //     }
  //   })
  // },

  inserted: (element, binding) => {
    let permissionValue = binding.value.permission
    if (!Array.isArray(permissionValue)) {
      permissionValue = [permissionValue]
    }

    if (!permissionValue) {
      throw new Error('You have to pass user`s permission to the directive: if-permitted')
    }

    auth.whenReady(() => {
      const isPermitted = auth.user.permissions.some((permission) => permissionValue.includes(permission.value))
      if (isPermitted === false) {
        element.parentNode.removeChild(element)
      }
    })
  }
}

// /**
//  * Create comment node
//  *
//  * @private
//  * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
//  */
// function commentNode (el, vnode) {
//   const comment = document.createComment(' ')

//   Object.defineProperty(comment, 'setAttribute', {
//     value: () => undefined
//   })

//   vnode.text = ' '
//   vnode.elm = comment
//   vnode.isComment = true
//   vnode.context = undefined
//   vnode.tag = undefined
//   vnode.data = {}

//   if (vnode.componentInstance) {
//     vnode.componentInstance.$el = comment
//   }

//   if (el.parentNode) {
//     // el.parentNode.replaceChild(comment, el)
//     el.parentNode.removeChild(el)
//   }
// }
