import moment from '@/core/moment-config'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

for (const rule in rules) {
  extend(rule, rules[rule])
}

extend('password', {
  validate: (value, { other }) => value === other,
  message: 'Das Passwort stimmt nicht überein.',
  params: [{ name: 'other', isTarget: true }]
})

extend('smallLetter', {
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line quotes
    const lower_case = new RegExp("^(?=.*[a-z])")

    return lower_case.test(value)
  },
  message: 'Das Passwort muss mindestens einen kleinen Buchstaben enthalten'
})

extend('bigLetter', {
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line quotes
    const upper_case = new RegExp("^(?=.*[A-Z])")

    return upper_case.test(value)
  },
  message: 'Das Passwort muss mindestens einen großen Buchstaben enthalten'
})

extend('hasNumber', {
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line quotes
    const has_number = new RegExp("^(?=.*[0-9])")

    return has_number.test(value)
  },
  message: 'Das Passwort muss mindestens eine Zahl enthalten'
})

extend('hasSpecial', {
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line quotes
    const has_special = new RegExp("^(?=.*[!@#$&*.:,;_-])")

    return has_special.test(value)
  },
  message: 'Das Passwort muss mindestens ein Sonderzeichen (!@#$&*.:,;_-) enthalten'
})

extend('olderThan18', {
  validate: value => {
    return moment().diff(moment(value), 'years', true) >= 18
  },
  message: 'Sie müssen mindestens 18 Jahre alt sein'
})

extend('hasKey', {
  params: ['key'], // list of parameter names
  validate (value, { key }) {
    return key in value
  }
})

extend('email', {
  params: ['key'], // list of parameter names
  validate (value, args) {
    const EMAILREG = /^[^\s@]+@[^\s@]+\.[A-Za-z]{2,63}$/

    return EMAILREG.test(value)
  }
})

extend('minWords', {
  params: ['number'], // list of parameter names
  validate (value, { number }) {
    let str = value
    str = str.replace(/(^\s*)|(\s*$)/gi, '')
    str = str.replace(/[ ]{2,}/gi, ' ')
    str = str.replace(/\n /gi, '\n')
    const len = str.split(' ').filter(o => o && o !== '""').length
    return len >= parseInt(number)
  },
  message: 'Geben Sie mindestens 10 Wörter ein'
})

extend('biggerThanStart', {
  validate: (value, { start }) => {
    // eslint-disable-next-line no-undef
    return (moment(start).diff(moment(value)) <= 0)
  },
  params: ['start'],

  message: '{_field_} muss größer sein als {_value_}.'
})

extend('biggerThanDateTime', {
  validate: (value, { start }) => {
    const date = moment().format('YYYY-MM-DD')
    return (moment(date + ' ' + start).diff(moment(date + ' ' + value)) <= 0)
  },
  params: ['start'],

  message: '{_field_} muss größer sein als {_value_}.'
})

extend('url', {
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,9}(:[0-9]{1,5})?(\/.*)?$/)
    return regex.test(value)
  },
  message: 'Ungültiges URL-Format'
})

extend('requiredDateRange', {
  validate: value => {
    return Array.isArray(value) && !value.some(o => o === null) && value.length === 2
  },
  message: 'Bitte wählen Sie einen Zeitraum aus'
})
