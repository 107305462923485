export default function (value) {
  if (value === null) {
    return null
  }

  // amazon bug? sometimes it gives us %252F instead of %2F and then the links do not work
  value = value.replace(/%252F/g, '%2F')

  if (value.indexOf('https://') > -1) {
    return value
  }

  if (window.location.hostname.indexOf('localhost') > -1) {
    return 'http://localhost' + value
  }

  if (window.location.hostname.indexOf('127.0.0.1') > -1) {
    return 'http://127.0.0.1' + value
  }

  return value
}
