import Vue from 'vue'
import data from '@/core/auth'

const Auth = new Vue({ data })

Auth.install = function () {
  Object.defineProperty(Vue.prototype, '$auth', {
    get () {
      return Auth
    }
  })
}

export default Auth
