export default {
  // When the bound element is inserted into the DOM...
  inserted (el, binding) {
    if ((binding.value === undefined || binding.value === null) || binding.value !== false) {
      // Focus element if not set to false
      setTimeout(() => {
        el.focus()
        // also, if has arg select v-focus.select, also mark / select input
        if (binding.arg === 'select') {
          el.select()
        }
      }, 100)
    }
  }
}
