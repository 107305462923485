import moment from '@/core/moment-config'

export default function (value, format = 'DD.MM.YY') {
  if (value === undefined || value === null) {
    return null
  }
  const now = moment()
  const formattedDate = moment(value)

  if (formattedDate.isSame(now, 'day')) {
    return 'Heute'
  } else if (formattedDate.isSame(now.subtract(1, 'days'), 'day')) {
    return 'Gestern'
  } else if (formattedDate.isSame(now.add(2, 'days'), 'day')) {
    return 'Morgen'
  } else {
    return formattedDate.format(format)
  }
}
