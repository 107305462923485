import { localize } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'

// Custom validation messages.
// FIXME: We have to remove all {_field_} values in this list.
de.messages.alpha = 'Nur Buchstaben erlaubt'
de.messages.alpha_dash = 'Nur Buchstaben und Striche erlaubt'
de.messages.alpha_num = 'Nur Buchstaben und Nummern erlaubt'
de.messages.alpha_spaces = 'Nur Buchstaben, Striche und Nummern erlaubt'
de.messages.between = 'Muss mindestens {min} und höchstens {max} sein'
de.messages.confirmed = 'Stimmt nicht überein'
de.messages.digits = 'Muss numerisch sein und exakt {length} Ziffern enthalten'
de.messages.dimensions = 'Muss {width} x {height} Bildpunkte groß sein'
de.messages.double = 'Muss eine gültige Dezimalzahl sein'
de.messages.email = 'Ungültige E-Mail-Adresse'
de.messages.excluded = 'Muss ein gültiger Wert sein'
de.messages.ext = 'Muss eine gültige Datei sein'
de.messages.image = 'Muss eine Bilddatei sein'
de.messages.integer = 'Muss eine ganze Zahl sein'
de.messages.length = 'Die Länge muss {length} sein'
de.messages.max = 'Darf nicht länger als {length} Zeichen sein'
de.messages.max_value = 'Darf höchstens {max} sein'
de.messages.mimes = 'Muss einen gültigen Dateityp haben'
de.messages.min = 'Muss mindestens {length} Zeichen lang sein'
de.messages.min_value = 'Muss mindestens {min} sein'
de.messages.numeric = 'Nur Zahlen sind erlaubt'
de.messages.oneOf = 'Muss ein gültiger Wert sein'
de.messages.regex = 'Das Format ist ungültig'
de.messages.required = 'Pflichtfeld'
de.messages.required_if = 'Pflichtfeld'
de.messages.size = 'Muss kleiner als {size}KB sein'
localize('de', de)
