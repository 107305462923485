import { v4 as uuid } from 'uuid'

const modal = {
  items: [],

  close (uuid, param, param2) {
    const idx = this.items.findIndex(modal => modal.uuid === uuid)
    if (idx !== -1) {
      if (typeof this.items[idx].onClose === 'function') {
        this.items[idx].onClose(param, param2)
      }

      this.items.splice(idx, 1)
      this.setBodyClasses()
    }
  },

  setBodyClasses () {
    document.body.className = this.items.some(o => o.backdrop) ? 'modal-open' : ''
  },

  open (modal) {
    if (
      this.items.some(
        (existingModal) =>
          existingModal.component.toString() === modal.component.toString() &&
          (!modal.data || JSON.stringify(existingModal.data) === JSON.stringify(modal.data))
      )
    ) {
      return null
    }

    modal.uuid = uuid()

    const item = Object.assign({
      component: null,
      size: 'md',
      zIndex: 1041,
      data: {},
      classes: {
        content: null
      },
      events: {
        onSelected: () => {}
      },
      onClose: () => {},
      resizable: false,
      draggable: false,
      backdrop: true
    }, modal)
    item.modalCloseFunc = () => {
      this.close(item.uuid)
    }
    this.items.push(item)
    this.setBodyClasses()

    return item
  },

  setModalDialog (uuid, pClass) {
    const idx = this.items.findIndex(modal => modal.uuid === uuid)
    if (idx !== -1) {
      this.items[idx].classes.dialog = pClass
    }
  },

  setModalAtrribute (uuid, attribute, value) {
    const idx = this.items.findIndex(modal => modal.uuid === uuid)
    if (idx !== -1) {
      this.items[idx][attribute] = value
    }
  },

  closeTop () {
    if (this.items.length) {
      this.close(this.items[this.items.length - 1].uuid)
    }
  },

  forceCloseAll () {
    this.items.splice(0, this.items.length)
    this.setBodyClasses()
  }
}

export default modal
