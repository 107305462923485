import Vue from 'vue'
import rest from '@/core/rest'

const ServerCache = new Vue({
  data: {
    userItems: {},
    workspaceItems: {}
  },

  methods: {
    init (data) {
      this.userItems = JSON.parse(data.userCache.cache)
      this.workspaceItems = JSON.parse(data.workspaceCache.cache)
    },

    clear () {
      this.userItems = {}
      this.workspaceItems = {}
    },

    async get (key, scope = 'user') {
      if (scope === 'user') {
        return key ? this.userItems[key] : this.userItems
      } else if (scope === 'workspace') {
        return key ? this.workspaceItems[key] : this.workspaceItems
      }
    },

    async set (key, value, scope = 'user') {
      if (scope === 'user') {
        this.userItems[key] = value
        this.storeUserCache(this.userItems)
      } else if (scope === 'workspace') {
        this.workspaceItems[key] = value
        this.storeWorkspaceCache(this.workspaceItems)
      }
    },

    storeUserCache (pdata) {
      const data = {
        cache: JSON.stringify(pdata)
      }
      rest.put('user/cache/user', data, () => {})
    },

    storeWorkspaceCache (pdata) {
      const data = {
        cache: JSON.stringify(pdata)
      }
      rest.put('user/cache/workspace', data, () => {})
    },

    save (pdata, cb = () => {}, scope = 'user') {
      const data = {
        cache: JSON.stringify(pdata)
      }
      rest.put('user/cache/' + scope, data, cb)
    }
  }
})

ServerCache.install = function () {
  Object.defineProperty(Vue.prototype, '$serverCache', {
    get () { return ServerCache }
  })
}

export default ServerCache
