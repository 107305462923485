const CacheManager = {
  items: {
    temp: {},
    stored: {}
  },

  clear () {
    this.items.temp = {}
    this.items.stored = {}
    delete localStorage.cache
  },

  init () {
    if (localStorage && localStorage.cache) {
      this.items.stored = JSON.parse(localStorage.cache)
    }
  },

  reset (key, callback) {
    this.items.temp[key] = callback()
    return this.items.temp[key]
  },

  get (key, callback) {
    if (this.items.temp[key]) {
      return this.items.temp[key]
    }

    this.items.temp[key] = callback()
    return this.items.temp[key]
  },

  resetStored (key, callback) {
    this.items.stored[key] = callback()
    CacheManager.save()
    return this.items.stored[key]
  },

  getStored (key, callback) {
    if (this.items.stored[key]) {
      return this.items.stored[key]
    }

    this.items.stored[key] = callback()
    CacheManager.save()
    return this.items.stored[key]
  },

  setStored (key, value) {
    this.items.stored[key] = value
    CacheManager.save()
  },

  deleteStored (key) {
    delete this.items.stored[key]
    CacheManager.save()
  },

  save () {
    if (localStorage) {
      localStorage.cache = JSON.stringify(this.items.stored)
    }
  }
}

export default CacheManager
