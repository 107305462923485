import moment from '@/core/moment-config'

export default function (value) {
  if (value === undefined || value === null) {
    return null
  }
  const seconds = moment().diff(moment(value), 'seconds')
  if (seconds < 60) {
    return 'gerade eben'
  } else if (seconds < 120) {
    return '1 Minute'
  } else if (seconds < 3600) {
    return Math.floor(seconds / 60) + ' Minuten'
  } else if (seconds < 7200) {
    return '1 Stunde'
  } else if (seconds < 86400) {
    return Math.floor(seconds / 3600) + ' Stunden'
  } else if (seconds < 172800) {
    return '1 Tag'
  } else if (seconds < 604800) {
    return Math.floor(seconds / 86400) + ' Tage'
  } else if (seconds < 1209600) {
    return '1 Woche'
  } else if (seconds < 2592000) {
    return Math.floor(seconds / 604800) + ' Wochen'
  } else if (seconds < 5184000) {
    return '1 Monat'
  } else if (seconds < 31536000) {
    return Math.floor(seconds / 2592000) + ' Monate'
  } else if (seconds < 63072000) {
    return '1 Jahr'
  } else {
    return Math.floor(seconds / 31536000) + ' Jahre'
  }
}
