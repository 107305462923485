import moment from '@/core/moment-config'

export default function (value, format = 'DD.MM.YY') {
  if (value === undefined || value === null) {
    return null
  }

  const today = moment().startOf('day')
  const targetDate = moment(value).clone().startOf('day')
  const diffDays = targetDate.diff(today, 'days')
  const diffWeeks = targetDate.diff(today, 'weeks')
  const diffMonths = targetDate.diff(today, 'months')

  let returnString = 'Heute'
  switch (diffDays) {
  case 1:
    returnString = 'Morgen'
    break
  case -1:
    returnString = 'Gestern'
    break
  case 2:
    returnString = 'Übermorgen'
    break
  case -2:
    returnString = 'Vorgestern'
    break
  default:
    if (diffDays > 1) {
      returnString = `In ${diffDays} Tagen`
    } else if (diffDays < -1) {
      returnString = `Vor ${Math.abs(diffDays)} Tagen`
    }
    break
  }

  // For weeks and months
  if (diffWeeks === 1) {
    returnString = 'In 1 Woche'
  } else if (diffWeeks > 1) {
    returnString = `In ${diffWeeks} Wochen`
  } else if (diffWeeks === -1) {
    returnString = 'Vor 1 Woche'
  } else if (diffWeeks < -1) {
    returnString = `Vor ${Math.abs(diffWeeks)} Wochen`
  }

  if (diffMonths === 1) {
    returnString = 'In 1 Monat'
  } else if (diffMonths > 1) {
    returnString = `In ${diffMonths} Monaten`
  } else if (diffMonths === -1) {
    returnString = 'Vor 1 Monat'
  } else if (diffMonths < -1) {
    returnString = `Vor ${Math.abs(diffMonths)} Monaten`
  }

  return returnString
}
