const firstTimeModals = [{
  uuid: 'offers',
  title: 'Angebote',
  src: 'images/helper-modal/side-help-offers-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
  Erstellen Sie mit wenigen Klicks <b>Angebote</b> für Kunden und Interessenten:
  </p>
  <ul class="modal-alert-like__content-list">
    <li>
      Mit <b>Textvorlagen</b> und <b>Platzhaltern</b> wiederkehrende Texte automatisch einsetzen
    </li>
    <li>
      <b>Entwurfsmodus</b> für den letzten Check vor dem Absenden
    </li>
    <li>
      Mit nur einem Klick <b>Auftrag aus Angebot heraus generieren</b>
    </li>
  </ul>
      `
}, {
  uuid: 'deals',
  title: 'Anfragen',
  src: 'images/helper-modal/side-help-deals-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
  Ihre Kundenanfragen können Sie hier als <b>digitale Anfragetaschen</b> anlegen und alles Wichtige darin verwalten:</p>
  <ul class="modal-alert-like__content-list">
    <li>
      Mit <b>Phasen</b> den aktuellen Bearbeitungsstand setzen
    <li>
      <b>Umsatzerwartung</b> kalkulieren
    </li>
    <li>
      <b>Angebote</b> schreiben
    </li>
    <li>
      <b>Aufgaben</b> verteilen
    </li>
    <li>
      <b>Ereignisse & Notizen</b> festhalten
    </li>
  </ul>
    `
}, {
  uuid: 'reviews',
  title: 'Kundenbewertungen',
  src: 'images/helper-modal/reviews.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
    Auf dieser Seite können Sie Ihre <strong>Kundenbewertungen</strong> verwalten, die Sie für Ihr Unternehmen erhalten.
  </p>
  <h3 class="modal-alert-like__tip-title">Tipps</h3>
  <ul class="modal-alert-like__content-list">
    <li>
      <strong>Kundenbewertungen</strong> erhöhen die Wahrscheinlichkeit, dass Sie von Interessenten kontaktiert werden. Laden Sie <strong>Kunden und Geschäftspartnern</strong> ein, Sie zu bewerten (mit dem grünen Button "+ Bewertung einholen").
    </li>
    <li>
      <strong>Antworten</strong> Sie möglichst auf jede Bewertung. Das zeigt, dass Ihnen die Bewertungen Ihrer Kunden wichtig sind.
    </li>
    <li>
      <strong>Kritische Bewertungen</strong> gehören zum Geschäftsleben dazu. Doch wie antworten Sie am besten auf negative Bewertungen? Erfahren Sie hier mehr dazu:
      <a class="modal-alert-like__content-list__ext-link" href="#">
        [Link]
        <svg class="modal-alert-like__content-list__ext-link__icon">
          <use xlink:href="/images/sprite.svg#icon-external-link" />
        </svg>
      </a>
    </li>
  </ul>
    `
}, {
  uuid: 'resubmission',
  title: 'Wiedervorlagen',
  src: 'images/helper-modal/resubmission.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description" style="margin-bottom: 0;">
    <strong>Wiedervorlagen</strong> eignen sich gut, um Sie nach einer gewissen Zeit an etwas zu erinnern. <strong>Beispiel:</strong> Ein Kunde ist prinzipiell an Ihren Leistungen interessiert, möchte aber diesbezüglich erst in 2 Monaten nochmal kontaktiert werden.<br><br>
    Sie können Wiedervorlagen auch als <strong>Aufgaben für die Zukunft</strong> sehen, die Sie nicht sofort erledigen können. Wiedervorlagen können Sie für Kontakte und Vorgänge wie Anfragen, Angebote und Rechnungen setzen.
  </p>
    `
}, {
  uuid: 'articles',
  title: 'Artikel',
  src: 'images/helper-modal/side-help-articles-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description" style="margin-bottom: 0;">
  Sie können Ihre <strong>Artikel</strong> hier zentral anlegen und bei der Erstellung von Angeboten, Aufträgen und Rechnungen mit wenigen Klicks einfügen. Dies erspart Ihnen viel Zeit, da Sie Artikelpreise und -beschreibungen nur einmal schreiben müssen.<br><br>
  Artikel können Ihre <strong>Dienstleistungen</strong>, aber auch <strong>Produkte und Verkaufsartikel</strong> sein.
  </p>
    `
}, {
  uuid: 'services',
  title: 'Auftragsarten',
  src: 'images/helper-modal/side-help-order-types-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
    <b>Auftragsarten</b> können Sie als eine Form von <b>Oberkategorie für bestimmte Leistungsbereiche</b> Ihres Unternehmens sehen. Bei einer Anwaltskanzlei wären das z.B. die unterschiedlichen Rechtsgebiete, die abgedeckt werden.
  </p>
  <p class="modal-alert-like__first-time-description">
  Auftragsarten können <b>mit Artikeln verknüpft werden.</b> Sobald Sie bei einer Anfrage / Auftrag die entsprechende Auftragsart auswählen, werden die verknüpften Artikel automatisch in die Kalkulation eingefügt. Diese kann dann z.B. als Grundlage für das Angebot dienen, welches Sie für den Kunden vorbereiten.
  </p>
    `
}, {
  uuid: 'order',
  title: 'Aufträge',
  src: 'images/helper-modal/side-help-orders-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
    Alles Wichtige für die Bearbeitung und Abwicklung eines Auftrags können Sie mit <b>digitalen Auftragstaschen</b> erledigen: 
  </p>
  <ul class="modal-alert-like__content-list">
    <li><b>Bearbeitungstand</b> setzen</li>
    <li><b>Aufgaben</b> verteilen</li>
    <li><b>Ereignisse & Notizen</b> festhalten</li>
    <li><b>Rechnungen</b> schreiben</li>
  </ul>
    `
}, {
  uuid: 'bills',
  title: 'Rechnungen',
  src: 'images/helper-modal/side-help-bills-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
    Erstellen Sie mit wenigen Klicks <b>Rechnungen</b> an Ihre Kunden:
  </p>
  <ul class="modal-alert-like__content-list">
    <li>Mit <b>Artikellisten</b> schnell <b>Artikel</b> einfügen</li>
    <li>Mit <b>Textvorlagen</b> und <b>Platzhaltern</b> wiederkehrende Texte automatisch einsetzen</li>
    <li><b>Entwurfsmodus</b> für den letzten Check vor dem Absenden</li>
  </ul>
    `
}, {
  uuid: 'textsnippets',
  title: 'Textvorlagen',
  src: 'images/helper-modal/side-help-templates-table.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
    Für den <b>Kopf- und Fußbereich Ihrer Dokumente</b> (z.B. Angebote, Rechnungen) können Sie <b>Textvorlagen</b> erstellen.
  </p>
  <p class="modal-alert-like__first-time-description">
    Mit Textvorlagen <b>sparen Sie viel Zeit</b> bei der Erstellung von Dokumenten wie Angeboten und Rechnungen, da Sie wiederkehrende Textpassagen schnell einfügen und bei Bedarf jederzeit anpassen können.
  </p>
    `
}
]

export default firstTimeModals
