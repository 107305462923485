'use strict'

const getLineItems = {
  // starter: {
  //   product_id: 'prod_NUIcwLozCZmrAY',
  //   monthly: 'price_1MjK1ACMqGSVyfjNqpcvvSyj',
  //   yearly: 'price_1MjK1ACMqGSVyfjNUoOitNFz',
  //   licenses: 1
  // },
  // pro: {
  //   product_id: 'prod_NUIcJYuNIH0C4Y',
  //   monthly: 'price_1MjK1gCMqGSVyfjN6z8FruP7',
  //   yearly: 'price_1MjK1gCMqGSVyfjNf8krri6N',
  //   licenses: 1
  // },
  // enterprise: {
  //   product_id: 'prod_NUJf5fixRcSj6p',
  //   monthly: 'price_1MjL1rCMqGSVyfjN0jtDiZHV',
  //   yearly: 'price_1MjL1rCMqGSVyfjNJTwVWvyA',
  //   licenses: 1
  // }
  // Production
  starter: {
    product_id: 'prod_NUJl9nUgpzadoS',
    monthly: 'price_1MjL7lCMqGSVyfjNG8GyHcJW',
    yearly: 'price_1MjL7lCMqGSVyfjN3Peo40iF',
    licenses: 1
  },
  pro: {
    product_id: 'prod_NUJlvIx6eCqH82',
    monthly: 'price_1MjL7tCMqGSVyfjN8SEgHiXQ',
    yearly: 'price_1MjL7tCMqGSVyfjNlUaEywyE',
    licenses: 1
  },
  enterprise: {
    product_id: 'prod_NUJlRy33Er9ksI',
    monthly: 'price_1MjL7wCMqGSVyfjNBVach5H7',
    yearly: 'price_1MjL7wCMqGSVyfjNWjl6tAdw',
    licenses: 1
  }
}

module.exports = {
  offerStatus: [
    { value: 'Draft', title: 'Entwurf' },
    { value: 'Open', title: 'Offen' },
    { value: 'Received', title: 'Abgeschlossen' },
    { value: 'Cancelled', title: 'Storniert' }
  ],
  stripeUpdateDate: new Date(2024, 2, 27),
  trialLicenses: 99999,
  trialMailTokens: 50,
  productLimits: {
    [getLineItems.starter.product_id]: 50,
    [getLineItems.pro.product_id]: 100,
    [getLineItems.enterprise.product_id]: 250
  },
  getLineItems,

  workEventTypes: [
    { icon: 'icon-px-phone-incoming', value: 'TelefonIncoming', title: 'Telefon (Eingang)' },
    { icon: 'icon-px-phone-outgoing', value: 'TelefonOutgoing', title: 'Telefon (Ausgang)' },
    { icon: 'icon-whatsapp', value: 'WhatsAppIncoming', title: 'Nachricht (Eingang)' },
    { icon: 'icon-whatsapp', value: 'WhatsAppOutgoing', title: 'Nachricht (Ausgang)' },
    { icon: 'icon-px-paper-plane-tilt-down', value: 'MailIncoming', title: 'E-Mail (Eingang)' },
    { icon: 'icon-px-paper-plane-tilt', value: 'MailOutgoing', title: 'E-Mail (Ausgang)' },
    { icon: 'icon-px-printer', value: 'FaxIncoming', title: 'Fax (Eingang)' },
    { icon: 'icon-px-printer', value: 'FaxOutgoing', title: 'Fax (Ausgang)' },
    { icon: 'icon-px-envelope-open', value: 'LetterIncoming', title: 'Brief (Eingang)' },
    { icon: 'icon-px-envelope-email', value: 'LetterOutgoing', title: 'Brief (Ausgang)' },
    { icon: 'icon-px-map-pin', value: 'Consultation', title: 'Beratung vor Ort' },
    { icon: 'icon-px-globe-simple', value: 'ConsultationOnline', title: 'Beratung Online' },
    { icon: 'icon-more', value: 'Reminder', title: 'Wiedervorlage' },
    { icon: 'icon-more', value: 'Other', title: 'Sonstige' },
    { icon: 'icon-px-chat-centered-dots', value: 'Contact', title: 'Kontaktformular' },
    { icon: 'icon-px-chat-teardrop-dots', value: 'ZendeskTicket', title: 'Zendesk Ticket' },
    { icon: 'icon-more', value: 'Ebay', title: 'eBay' },
    { icon: 'icon-phone', value: 'Callback', title: 'Rückrufservice' }
  ],
  
  activityMap: {
    systemlogs: [
      // teamapplication
      'onApplicationAccepted',
      'onApplicationRejected',
      'onApplicationStatusChanged',
  
      'DEMO_Anfrage_erstellt',
      'DEMO_Angebot',
      'DEMO_Auftrag_erstellt',
      'DEMO_Kontaktformular',
      'DEMO_Rechnung',
      // 'GeneralActivity',
      'multipleObjects',
      'onAwardEMAIL',
      'onAwardPUSH',
      'onAwardSMS',
      'onBillDownloaded',
      'onCloneWork',
      'onContactFormular',
      'onCreate',
      'onCreateBillReclamation',
      'onCreatedProvision',
      'onCreateProvisionReclamation',
      'onCreateWorkCancelReclamation',
      'onDeleteBill',
      'onDeleteCollaborator',
      'onDeleteObject',
      'onNewObject',
      'onUpdateObject',
      'onDeleteProduct',
      'onDeleteProperty',
      'onDeleteService',
      'onDeleteTrackingLink',
      'onDeleteViolationStatus',
      'onDeleteWorkContact',
      'onDeleteWorkOffer',
      'onExpiredSuggestion',
      'onFailedAwardEMAIL',
      'onFailedAwardSMS',
      'onFailedEmail',
      'onFailedSMS',
      'onImported',
      'onNewCollaborator',
      'onNewDescription',
      'onNewProperty',
      'onNewService',
      'onNewServices',
      'onNewSMS',
      'onNewTrackingLink',
      'onNewWorkContact',
      'onNewWorkOffer',
      'onWorkOfferSent',
      'onProvisionConfirmed',
      'onProvisionDeleted',
      'onRejected',
      'onSolveBillReclamation',
      'onSolveWorkCancelReclamation',
      'onSubmittedProvision',
      'onUpdate',
      'onUpdateBillPaidStatus',
      'onUpdatedAssignee',
      'onUpdatedSourceType',
      'onUpdatedCustomFields',
      'onUpdateLostReason',
      'onUpdateProperty',
      'onUpdateTrackingLink',
      'onUpdateViolationStatus',
      'onUpdateWorkCancelReclamation',
      'onUpdateWorkContact',
      'onUpdateWorkDeclineStatus',
      'onUploadedBill',
      'onUserContactUpdate',
      'onUserJoinBusinessTeam',
      'onImportFromAi',
      'onImportFromApi'
    ],
    status: [
      'DEMO_Anfrage',
      'DEMO_Status',
      'onAccepted',
      'onConvertedWork',
      'DEMO_Anfrage_abgeschlossen',
      'DEMO_Auftrag_abgeschlossen',
      'onResettedWork',
      'onSuggested',
      'onUpdateStatus',
      'onUpdateUserContactStatus',
    ],
    tasks: [
      'DEMO_Aufgabe',
      'DEMO_Aufgabe_status_geändert',
      'onCreateTask',
      'onDeleteTask',
      'onUpdateStatusTask',
      'onUpdateTask',
    ],
    appointments: [
      'DEMO_Termin',
      'onDeleteAppointment',
      'onNewAppointment',
      'onUpdateAppointment',
    ],
    resubmissions: [
      'onCreateResubmission',
      'onDeleteResubmission',
      'onUpdateResubmission',
      'onUpdateStatusResubmission',
    ],
    notes: [
      'onCreateNote',
      'onDeleteNote',
      'onNewNote',
      'onUpdateNote',
    ],
    documents: [
      'DEMO_Dokument',
      'onDeleteFile',
      'onNewFile',
      'onUpdateFile',
    ],
    comments: [
      'onCreateComment',
      'onDeleteComment',
      'onUpdateComment',
    ],
    eventlogs: [
      'onDeleteEvent',
      'onDeleteEventFile',
      'onNewEvent',
      'onUpdateEvent'
    ],
    events: [
      'DEMO_Sonstige',
      'DEMO_E-Mail_(Ausgang)',
      'DEMO_Telefon_(Ausgang)',
      'DEMO_Telefon_(Eingang)',
    ]
  }
  
}