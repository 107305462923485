import Vue from 'vue'

const SharedData = new Vue({ data: { isSubmitting: false } })

SharedData.install = function () {
  Object.defineProperty(Vue.prototype, '$sharedData', {
    get () { return SharedData }
  })
}

export default SharedData
