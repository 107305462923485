import auth from '@/core/auth'

const Permitted = {
  async check (permission) {
    return new Promise((resolve) => {
      auth.whenReady(() => {
        let result = null

        if (auth.isAdmin()) {
          result = true
        } else {
          result = auth.user.permissions.some(p => p.value === permission)
        }

        resolve(result)
      })
    })
  }
}

export default Permitted
